@import '../../libs/react-mpk/styles/var.scss';
@import url('https://fonts.googleapis.com/css?family=Urbanist:300,400,500,600,700');


card{
    background-color: "#FFFFFF";
    width: "100%";
     
}
.title{
font-family: Urbanist;
font-size: 24px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
padding-bottom: 15px;
}

.disabled {
    font-family: 'Urbanist' !important;
    background-color:#D8D8D8;
  }
.fields {
    font-family: 'Urbanist' !important;
    background-color: rgba(36, 107, 253, 0.08);
  }
  .field:focus:hover {
    font-family: 'Urbanist' !important;
    background-color: rgba(36, 107, 253, 0.08);
  }
  .icon {
    color: #000000;
  }
  .icons {
    color: #7096F0;
  }

  .label{
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #212121;
  }

.ChangePass{
    color: #F26764;
    border-radius: 100px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:10px;
    padding-bottom:10px;
    background-color: #F7555514;

}