@import '../../styles/var';
@import url('https://fonts.googleapis.com/css?family=Urbanist:300,400,500,600,700');

.mpk-commandbar{
  height: 64px;
  background-color: $mpk-background-color;
}

.mpk-font{
  font-size: 16px;
  font-weight: 700;
  font-family: 'Urbanist';
  line-height: 24px;
}