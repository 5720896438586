@import '../../styles/var.scss';

.react-datepicker-wrapper {
  width: 100%;
}

.mpk-custom-input{
  position: relative;
  .message{
    font-size: $mpk-font-size-NS;
    padding: $mpk-padding-S 0;
    .rmd-icon--font{
      font-size: 16px !important;
    }
    &.error-text{
      color: $mpk-color-warn;
      .rmd-icon--font{
        color: $mpk-color-warn;
      }
    }
    &.help-text{
      color: $mpk-font-color-D3;
    }
  }
  ul.rmd-list{
    top: 0 !important;
    position: relative !important;
    left: 0 !important;
    .rmd-list-item__text{
      white-space: pre-wrap !important;
    }
  }
}

.react-select-material input {
  padding: 16px !important;
  font-size: 1em;
  font-weight: 400;
}

.react-select-label {
  position: relative;
  top: 9px;
  z-index: 10;
  background: white;
  left: 16px;
}

.filter {
  &__menu {
    margin: 0.125rem auto;
  }

  &__placeholder{
    margin: 0 10px !important;
  }

  &__single-value {
    margin: 0 10px !important;
  }
  .fields {
    font-family: 'Urbanist' !important;
    background-color: rgba(36, 107, 253, 0.08);
  }
  .field:focus:hover {
    font-family: 'Urbanist' !important;
    background-color: rgba(36, 107, 253, 0.08);
  }
  .icon {
    color: #000000;
  }
  .icons {
    color: #7096F0;
  }
  &__option {
    background-color: white;
    padding: 16px !important;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;

    &--is-focused {
      background-color: lightblue;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }
}