@import './libs/react-mpk/styles/mpk.scss';

.chip-success {
    background: #4caf50;
    color: white;
    font-size: 10px;
    cursor: pointer;
}

.chip-failed {
    background: #f44336;
    color: white;
    font-size: 10px;
    cursor: pointer;
}

._loading_overlay_wrapper {
    height: 100%;
    width: 100%;
}

._loading_overlay_overlay {
    height: 100%;
    width: 100%;
}

._loading_overlay_content {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 50%;
}

._loading_overlay_spinner {}

.ql-toolbar {
    width: 100%;
}

.ql-editor{
    min-height: 500px;
}

:root{
    --rmd-tabs-indicator-color: #f04438;
    --rmd-theme-primary: $mpk-primary-2;
    --rmd-button--secondary: #37474f;
}
.rmd-button--primary{
background-color: $mpk-primary-2;
}
.rmd-button--secondary{
background-color: #37474f;
}

.rmd-tabs-indicator-color {
color: #f04438
}
