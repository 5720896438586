@import "../../libs/react-mpk/styles/var.scss";
@import url("https://fonts.googleapis.com/css?family=Urbanist:300,400,500,600,700");

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 15px;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 25px;
}

.card-saldo-emeterai {
  background: linear-gradient(286.17deg, #ff8a9b 0%, #ff4d67 100%);
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 65%;
}

.card-saldo-esign {
  background: linear-gradient(286.17deg, #587fff 0%, #0044ff 100%);
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 65%;
}

.card-saldo-dms {
  background: linear-gradient(286.17deg, #ffab3e 0%, #ff7300 100%);
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 65%;
}

.card-title {
  padding-top: 20px;
  padding-left: 41px;
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
}
.card-content {
  padding-left: 41px;
  font-family: "Roboto";
  font-size: 55px;
  font-weight: 900;
  text-align: left;
  color: #ffffff;
  margin-bottom: -5px;
}
