@import '../../styles/var.scss';

.mpk-sidebar{
  width: 260px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border-right:1px solid rgba(156, 155, 155, 0.219);
  background: $mpk-sidebar-color;
  .mpk-sidebar-footer{
    height: 32px;
    .btn-support-center{
      padding: 0 16px;
      span{
        line-height: 2.2;
      }
    }
  }
}