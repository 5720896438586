@import '../../../libs/react-mpk/styles/var.scss';
@import url('https://fonts.googleapis.com/css?family=Urbanist:300,400,500,600,700');

.container-paper .container {
  width: auto;
  margin: 0 auto;
}

.bg {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  // z-index: 1;
  opacity: .5;
  background: #f4f4f2;
}

.bg .parallelogram-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .54;
  -webkit-transform: skew(56deg);
  -moz-transform: skew(56deg);
  -o-transform: skew(56deg);
  background: #e4e4de;
  background: -moz-linear-gradient(top, #e4e4de 56%, #f4f4f2 100%);
  background: -webkit-linear-gradient(top, #e4e4de 56%, #f4f4f2 100%);
  background: linear-gradient(to bottom, #e4e4de 56%, #f4f4f2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 );
}
.forgotPassword {
    width: 100%;
    text-align: center;
    color: #7096F0;
    margin-top:20px;
    margin-bottom:20px;
    font-size:16px;
    font-family: 'Urbanist';
}
.donthaveACcount{
  text-align: center;
  width: 100%;
  font-family: 'Urbanist';
  color: #757575;
  font-size:14px;
  margin-top:10px;
    margin-bottom:10px;
}
.ButtonLogin{
  text-align: center;
  width: 100%;
  font-family: 'Urbanist';
  color: #ffffff;
  font-size:14px;
  font-weight: bolder;
  text-transform: capitalize;
}
.bg .parallelogram-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 1;
  -webkit-transform: skew(56deg);
  -moz-transform: skew(56deg);
  -o-transform: skew(56deg);
  background: #f4f4f2;
  background: -moz-linear-gradient(top, #e4e4de 0%, #f4f4f2 50%);
  background: -webkit-linear-gradient(top, #e4e4de 0%, #f4f4f2 50%);
  background: linear-gradient(to bottom, #e4e4de 0%, #f4f4f2 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 );
}

.mpk-layout.column {
  flex-direction: column;
}

.decorative-text {
  font-size: 25pt;
  font-weight: bold;
  font-family: 'Urbanist';
  text-align: center;
  line-height: 20px;
  padding-top: 15px;
}
.remember {
  font-size: 13pt;
  font-weight: 600;
  font-family: 'Urbanist';
  margin-left: 0.5rem;
}
.field {
  font-family: 'Urbanist' !important;
  background-color: #FAFAFA;
}
.subTitle {

  font-family: 'Urbanist';
  font-weight: 700;


}
.textBody {
  font-family: 'Urbanist';
  text-align: center;
  font-size: 12pt;

}
.text1 {
  font-family: 'Urbanist';
  text-align: center;
  font-size: 12pt;
  margin-top:-20px;
  margin-right: 50px;
  margin-left: 50px;

}

.appbar{
  background: $mpk-appbar-color;
  height: 64px;
  width: 100%;
  padding: 0 16px;
  &.inverse{
    background: linear-gradient(-64deg, $mpk-primary-0, $mpk-primary-1, $mpk-primary-1, $mpk-primary-1, $mpk-primary-1, $mpk-primary-1);
    > *, .rmd-button {
      color: white
    }
  }
}

// .container-paper .container {
//   margin: 0 auto;
//   text-align: center;
//   // margin-bottom: 32px;
//   // padding-top: 28px;
// }

.container-paper .container .brand {
  text-align: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.mpk-layout.align-center {
  align-items: center;
}

.mpk-layout {
  display: flex;
  justify-content: flex-start;
}

.container-paper .container .footer-info {
  width: 400px;
  margin: 15px auto;
  z-index: 10;
  line-height: 12px;
  border: none;
  background: none;
}

.mpk-layout > .flex {
  flex: 1;
}

.container-paper .container .footer-info .pjk b {
  color: #c34528;
  font-family: "Source Sans Pro", sans-serif;
  // z-index: 10;
}

.mpk-font-size-NS {
  font-size: 12px !important;
}

.container-paper .container .footer-info .pjk .link > a {
  display: inline-block;
  padding: 0 4px;
  font-size: 10px;
  position: static;
  outline: none;
  text-decoration: dotted;
  color: rgba(0, 0, 0, 0.72);
  // z-index: 10;
}

a {
  color: #2883e5;
}

.mpk-mitra-djp {
  border-radius: 4px;
  padding: 4px;
  max-width: 80px;
  background: rgba(0, 0, 0, 0);
}

.mpk-font-color-D1 {
  color: rgba(0, 0, 0, 0.96) !important;
}

.mpk-mitra-djp .body {
  line-height: 1;
  border-left: thin dotted rgba(0, 0, 0, 0.52) !important;
}

.mpk-margin-S.margin-left {
  margin-left: 8px;
}

.mpk-padding-S.padding-left {
  padding-left: 8px;
}

.mpk-font-size-S {
  font-size: 10px !important;
}

.mpk-font-color-D2 {
  color: rgba(0, 0, 0, 0.72) !important;
}

.mpk-link {
  color: #2883e5;
  cursor: pointer !important;
}

.mpk-mitra-djp .body .djp {
  letter-spacing: 1px;
}

.mpk-font-weight-B {
  font-size: 15px !important;
  font-weight: 500;
}



@media only screen and (max-width: 845px) {
   
}