$mpk-background-color: #f3f0ed;
$mpk-white-color: #ffffff;
$mpk-primary-0:  #FF8F00;
$mpk-primary-1:  #f04438;
$mpk-primary-2: #c3362b;

$mpk-font-size-XS:8px;
$mpk-font-size-S:10px;
$mpk-font-size-NS:12px;
$mpk-font-size-N:14px;
$mpk-font-size-M:16px;
$mpk-font-size-L:18px;
$mpk-font-size-XL:20px;
$mpk-font-size-XXL:24px;
$mpk-font-size-XXXL:36px;
$mpk-font-size-JUMBO:48px;

$mpk-font-weight-T:200;
$mpk-font-weight-L:300;
$mpk-font-weight-R:400;
$mpk-font-weight-M:500;
$mpk-font-weight-B:600;
$mpk-font-weight-XB:800;

$mpk-border-color-dark:rgba(0,0,0,.08);
$mpk-border-color-light:rgba(255,255,255,.1);

$mpk-padding-XS:4px;
$mpk-padding-S:8px;
$mpk-padding-NS:12px;
$mpk-padding-N:16px;
$mpk-padding-M:24px;
$mpk-padding-L:48px;
$mpk-padding-XL:56px;

$mpk-margin-XS:4px;
$mpk-margin-S:8px;
$mpk-margin-NS:12px;
$mpk-margin-N:16px;
$mpk-margin-M:24px;
$mpk-margin-L:48px;
$mpk-margin-XL:56px;

$mpk-color-warn:#f1420c;
$mpk-color-progress:#1565c0;
$mpk-color-success:#558b2f;
$mpk-color-alert:#ff8f00;
$mpk-color-idle:#9e9e9e;

$mpk-color-link: #2883e5;

$mpk-font-color-L1:rgba(255,255,255,1);
$mpk-font-color-L2:rgba(255,255,255,.92);
$mpk-font-color-L3:rgba(255,255,255,.54);
$mpk-font-color-D1:rgba(0,0,0,.96);
$mpk-font-color-D2:rgba(0,0,0,.72);
$mpk-font-color-D3:rgba(0,0,0,.48);
$mpk-font-color-D4:rgba(0,0,0,.24);

$mpk-grey-1:#fff9f6;
$mpk-grey-2:#eee;

$mpk-dark-grey-1:#3a3a3a;

$mpk-dark-1:#263238;
$mpk-dark-logo:#222;

$mpk-primary-color: #f04438;
$mpk-black-color: #000000;
$mpk-secondary-color:blue;
$mpk-hint-background-color:#f6f6e2;
$mpk-hint-color: #5f683d;

$mpk-action-footer-color: #f7efe8;

$mpk-appbar-color: #f9f5f2;
@if variable-exists(md-appbar-color){
  $mpk-appbar-color:$md-appbar-color;
}

$mpk-appbar-theme: 'light';
@if variable-exists(md-appbar-theme) {
  $mpk-appbar-theme: $md-appbar-theme;
}

$mpk-sidebar-theme:'light';
@if variable-exists(md-sidebar-theme) {
  $mpk-sidebar-theme: $md-sidebar-theme;
}

$mpk-sidebar-color: $mpk-white-color;
@if variable-exists(md-sidebar-color){
  $mpk-sidebar-color:$md-sidebar-color;
}

$mpk-gradient-primary-1:$mpk-primary-color;
@if variable-exists(md-gradient-primary-1){
  $mpk-gradient-primary-1:$md-gradient-primary-1;
}

$mpk-gradient-primary-2:$mpk-primary-color;
@if variable-exists(md-gradient-primary-2){
  $mpk-gradient-primary-2:$md-gradient-primary-2;
}

$mpk-media-xs: 600px;
$mpk-media-sm: 960px;
$mpk-media-md: 1280px;
$mpk-media-lg: 1920px;

$mpk-article-width:692px;